import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { MARK_FUNCS } from "./TypesDefs/marks";
import { BLOCK_FUNCS } from "./TypesDefs/blocks";
import { INLINE_FUNCS } from "./TypesDefs/inlines";
import { CUSTOM_FUNCS, CUSTOM_TYPES } from "./TypesDefs/custom";
import { TABLE_FUNCS, TABLE } from "./TypesDefs/table";

const defaultRenderFuncs = {
  default: (node, children) => <div>{children}</div>,
  text: (node) => node.value,
  empty: (node, children) => <>{children}</>,

  ...MARK_FUNCS,
  ...BLOCK_FUNCS,
  ...INLINE_FUNCS,
  ...TABLE_FUNCS,
  ...CUSTOM_FUNCS,
};

export const renderRichText = (doc, options) => {
  let context = {
    isSample: options?.isSample || false,
    references: doc?.references || [],
    renderFuncs: { ...defaultRenderFuncs, ...options?.renderFuncs },
  };

  return parseNode(
    typeof doc.raw === "string" ? JSON.parse(doc.raw) : doc.raw,
    context
  );
};

const parseNode = (node, context, nextNode = null, prevNode = null) => {
  node = checkCustomTypes(node, context);
  let { renderFuncs } = context;
  let { nodeType, content, marks } = node;

  // if (nodeType === BLOCKS.DOCUMENT) console.log(node);

  let renderFunc = renderFuncs[nodeType] || renderFuncs["default"];

  // inject asset reference
  if (
    [
      BLOCKS.EMBEDDED_ENTRY,
      BLOCKS.EMBEDDED_ASSET,
      INLINES.ENTRY_HYPERLINK,
      INLINES.EMBEDDED_ENTRY,
    ].includes(nodeType)
  ) {
    node.data.target = context?.references?.find(
      (ref) => ref.contentful_id === node.data.target.sys.id
    );
  }

  let renderedContent = content?.map((x, i) =>
    parseNode(x, context, content[i + 1] || null, content[i - 1] || null)
  );

  let rendered = renderFunc(
    node,
    renderedContent?.map((x, i) => (
      <React.Fragment key={i}>{x}</React.Fragment>
    )),
    nextNode,
    prevNode
  );

  if (nodeType === "text" && marks.length > 0) {
    marks.forEach(({ type }) => {
      let markFunc = renderFuncs[type];
      if (markFunc) {
        rendered = markFunc(rendered);
      }
    });
  }

  return rendered;
};

const checkCustomTypes = (node, context) => {
  let oldNode = { ...node };

  if (oldNode.nodeType === BLOCKS.DOCUMENT) {
    // return first block of text for a sample
    if (context.isSample) {
      node.content = [
        oldNode.content.find((x) =>
          [BLOCKS.PARAGRAPH, BLOCKS.QUOTE].includes(x.nodeType)
        ),
      ];
      return node;
    }

    let newContent = [...oldNode.content];

    // Remove last paragraph if it is empty
    let lastIndex = newContent.length - 1;
    let lastNode = newContent[lastIndex];
    if (
      lastNode.nodeType === BLOCKS.PARAGRAPH &&
      lastNode.content[0].value.trim() === ""
    ) {
      newContent.splice(lastIndex, 1);
    }

    // Find paragraph nodes with just links to make buttons
    newContent.map((x) => {
      if (x.nodeType === BLOCKS.PARAGRAPH) {
        convertParagraphToButtonGroup({ ...x }, x);
      }
      return x;
    });

    // Group assets that are adjacent
    let assetGroup = [];
    newContent = newContent.reduce((altContent, x, i) => {
      if (x.nodeType === BLOCKS.EMBEDDED_ASSET) {
        assetGroup.push(x);

        if (i === newContent.length - 1) {
          altContent.push({
            nodeType: CUSTOM_TYPES.EMBEDDED_ASSET_GROUP,
            content: assetGroup,
            data: {},
          });
        }
      } else {
        if (assetGroup.length > 0) {
          altContent.push({
            nodeType: CUSTOM_TYPES.EMBEDDED_ASSET_GROUP,
            content: assetGroup,
            data: {},
          });
          assetGroup = [];
        }
        altContent.push(x);
      }

      return altContent;
    }, []);

    // group (heading, paragraph, buttonGroup) in CALL_TO_ACTION
    let c2aGroup = [];
    let lastNodeType = "";
    let continueGroup = false;
    newContent = newContent.reduce((altContent, x) => {
      if (x.nodeType.includes("heading") && !lastNodeType.includes("heading")) {
        c2aGroup.push(x);
        continueGroup = true;
      } else if (
        x.nodeType === BLOCKS.PARAGRAPH &&
        lastNodeType.includes("heading") &&
        continueGroup
      ) {
        c2aGroup.push(x);
      } else if (
        (x.nodeType === CUSTOM_TYPES.BUTTON_GROUP ||
          x.nodeType === CUSTOM_TYPES.BUTTON_SINGLE) &&
        lastNodeType === BLOCKS.PARAGRAPH &&
        continueGroup
      ) {
        c2aGroup.push(x);
        altContent.push({
          nodeType: CUSTOM_TYPES.CALL_TO_ACTION,
          content: c2aGroup,
          data: {},
        });
        c2aGroup = [];
        continueGroup = false;
      } else {
        c2aGroup.forEach((x) => altContent.push(x));
        c2aGroup = [];
        continueGroup = false;
        altContent.push(x);
      }
      lastNodeType = x.nodeType;

      return altContent;
    }, []);

    node.content = newContent;
  } else if (oldNode.nodeType === TABLE.TABLE) {
    let headRows = [];
    let bodyRows = [];
    oldNode.content.forEach((x) => {
      if (x.content[0].nodeType === TABLE.TABLE_HEADER_CELL) {
        headRows.push(x);
      } else {
        bodyRows.push(x);
      }
    });

    let newContent = [];

    if (headRows.length > 0)
      newContent.push({
        nodeType: TABLE.TABLE_HEAD,
        content: headRows,
        data: {},
      });

    if (bodyRows.length > 0)
      newContent.push({
        nodeType: TABLE.TABLE_BODY,
        content: bodyRows,
        data: {},
      });

    node.content = newContent;
  } else if (oldNode.nodeType === BLOCKS.PARAGRAPH) {
    convertParagraphToButtonGroup(oldNode, node);
  } else if (
    [BLOCKS.LIST_ITEM, TABLE.TABLE_CELL, TABLE.TABLE_HEADER_CELL].includes(
      oldNode.nodeType
    )
  ) {
    // overrides paragraph in list item
    oldNode.content[0].nodeType = "empty";
    node.content = oldNode.content;
  }

  return node;
};

const convertParagraphToButtonGroup = (oldNode, node) => {
  const hyperlinkCount = oldNode.content.filter(
    (x) => x.nodeType === INLINES.HYPERLINK
  ).length;
  const isTextEmpty = oldNode.content
    .filter((x) => x.nodeType === "text")
    .every((x) => x.value?.trim() === "");

  if (hyperlinkCount > 0 && isTextEmpty) {
    node.nodeType = CUSTOM_TYPES.BUTTON_SINGLE;
    node.content = oldNode.content
      .filter((x) => x.nodeType === INLINES.HYPERLINK)
      .map((x) => {
        x.nodeType = CUSTOM_TYPES.BUTTON;

        return x;
      });

    if (hyperlinkCount > 1) {
      node.nodeType = CUSTOM_TYPES.BUTTON_GROUP;
    }
  }
};
