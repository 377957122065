import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { parseSlug } from "../../utils";

export const BLOCK_FUNCS = {
  [BLOCKS.DOCUMENT]: (node, children) => <>{children}</>,
  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
  [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
  [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
  [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
  [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
  [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
  [BLOCKS.HR]: () => <hr />,
  [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
  [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
  [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
  [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
  [BLOCKS.EMBEDDED_ENTRY]: (node) => (
    <a href={parseSlug(node.data.target.slug)}>{node.data.target.title}</a>
  ),
  [BLOCKS.EMBEDDED_ASSET]: () => "needs to be implemented",
};
