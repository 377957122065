import React from "react";
import { INLINES } from "@contentful/rich-text-types";
import { parseSlug } from "../../utils";

export const INLINE_FUNCS = {
  [INLINES.HYPERLINK]: (node, children) => (
    <a href={node.data.uri}>{children}</a>
  ),
  [INLINES.ENTRY_HYPERLINK]: (node, children) => (
    <a href={parseSlug(node.data.target.slug)}>{children}</a>
  ),
  [INLINES.ASSET_HYPERLINK]: () => "needs to be implemented",
  [INLINES.EMBEDDED_ENTRY]: (node) => (
    <a href={parseSlug(node.data.target.slug)}>{node.data.target.title}</a>
  ),
};
