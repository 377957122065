import React from "react";
import { MARKS } from "@contentful/rich-text-types";

export const MARK_FUNCS = {
  [MARKS.BOLD]: (text) => <b>{text}</b>,
  [MARKS.ITALIC]: (text) => <i>{text}</i>,
  [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
  [MARKS.CODE]: (text) => <code>{text}</code>,
  superscript: (text) => <sup>{text}</sup>,
  subscript: (text) => <sub>{text}</sub>,
};
