import React from "react";

export const CUSTOM_TYPES = {
  BUTTON_SINGLE: "button-single",
  BUTTON_GROUP: "button-group",
  BUTTON: "button",
  EMBEDDED_ASSET_GROUP: "embedded-asset-group",
  CALL_TO_ACTION: "call-to-action",
};

export const CUSTOM_FUNCS = {
  [CUSTOM_TYPES.BUTTON_GROUP]: (node, children) => (
    <div className="button-group">{children}</div>
  ),
  [CUSTOM_TYPES.BUTTON_SINGLE]: (node, children) => (
    <div className="button-single">{children}</div>
  ),
  [CUSTOM_TYPES.BUTTON]: (node, children) => (
    <a href={node.data.uri}>
      <button>{children}</button>
    </a>
  ),
  [CUSTOM_TYPES.EMBEDDED_ASSET_GROUP]: (node, children) => (
    <div className="embedded-asset-group">{children}</div>
  ),
  [CUSTOM_TYPES.CALL_TO_ACTION]: (node, children) => (
    <div className="call-to-action">
      <h2 className="text-4xl text-center font-new-tegomin">
        {node.content[0].content[0].value}
      </h2>
      <p className="p-alt !max-w-lg text-center mb-4">
        {node.content[1].content[0].value}
      </p>
      {children[2]}
    </div>
  ),
};
