import React from "react";

export const TABLE = {
  TABLE: "table",
  TABLE_HEAD: "table-head",
  TABLE_BODY: "table-body",
  TABLE_ROW: "table-row",
  TABLE_HEADER_CELL: "table-header-cell",
  TABLE_CELL: "table-cell",
};

export const TABLE_FUNCS = {
  [TABLE.TABLE]: (node, children) => <table>{children}</table>,
  [TABLE.TABLE_HEAD]: (node, children) => <thead>{children}</thead>,
  [TABLE.TABLE_BODY]: (node, children) => <tbody>{children}</tbody>,
  [TABLE.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
  [TABLE.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
  [TABLE.TABLE_CELL]: (node, children) => <td>{children}</td>,
};
